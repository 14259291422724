export const faqsVehicles = [
  {
    question:
      "What happens after I pay 499 on the website to reserve my vehicle  ?",
    answer:
      "After you have completed the payment online for an electric vehicle, an exclusive Turno EV expert will be assigned for you to further understand your requirements and to schedule a test drive. In case you change your mind, we will initiate the full refund of your booking fee.",
    soundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq7.mp3`,
    kannadaSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq7-kannada.mp3`,
    tamilSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-tamil.m4a`,
    teluguSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-telugu.mp3`,
    marathiSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-marathi.mp3`,
  },
  {
    question: "How much time does it take to charge and where can I charge ?", //"How much time does it take to charge and where can I charge ?",
    answer:
      "It takes 3 to 3.5 hours to fully charge the three wheeler electric vehicle. You can charge at home or at charging stations closest to you. The information about charging stations for commercial electric vehicles is available on google maps and other third-party apps.",
    soundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1.mp3`,
    kannadaSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-kannada.mp3`,
    tamilSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq2-tamil.m4a`,
    teluguSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq2-telugu.mp3`,
    marathiSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq2-marathi.mp3`,
  },
  {
    question: "Is there a subsidy I can avail on electric commercial vehicle?", //"What is the subsidy I get on Electric vehicles?",
    answer:
      "Yes, subsidy is available on electric luggage vehicles and may vary from state to state. The subsidy is already included in the on-road price of the electric vehicles. In case you wish to know the exact amount of subsidy by vehicle model and location, you can contact Turno EV experts through our website.",
    soundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq2.mp3`,
    kannadaSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq2-kannada.mp3`,
    tamilSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq3-tamil.m4a`,
    teluguSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq3-telugu.mp3`,
    marathiSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq3-marathi.mp3`,
  },
  {
    question:
      "Can I exchange my old vehicle for a new electric vehicle through Turno ?",
    answer:
      "Yes, we do have a vehicle exchange offer. If you are interested in buying a new charging electric commercial vehicle from Turno, you can simply mention that to us while filling up the online form  below.",
    soundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq3.mp3`,
    kannadaSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq3-kannada.mp3`,
    tamilSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq4-tamil .m4a`,
    teluguSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq4-telugu.mp3`,
    marathiSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq4-marathi.mp3`,
  },
  {
    question: "What will be the warranty for the new vehicle ?",
    answer:
      "We provide 3 years of warranty on the electric vehicle battery. Also, if you wish to sell your vehicle to Turno, we provide a guaranteed resale value up to ₹1.5 lakhs after 3 years on commercial electric three wheeler vehicles.",
    soundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq4.mp3`,
    kannadaSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq4-kannada.mp3`,
    tamilSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq5-tamil.m4a`,
    teluguSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq5-telugu.mp3`,
    marathiSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq5-marathi.mp3`,
  },
  {
    question: "Can I load up the vehicle beyond the load limit?",
    answer:
      "Yes, you can increase the load beyond the limit. In case you load more than the limit in the electric auto, the battery will discharge very quickly. So, we recommend following the instructions as per the manufacturer.",
    soundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq5.mp3`,
    kannadaSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq5-kannada.mp3`,
    tamilSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq6-tamil.m4a`,
    teluguSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq6-telugu.mp3`,
    marathiSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq6-marathi.mp3`,
  },
  {
    question: "Where do I service my vehicle and what will it cost me?",
    answer:
      "Servicing can be done at the nearest vehicle showroom, you will only need to service once you completed 10,000 km, the cost of servicing is minimal when compared with  diesel vehicle.",
    soundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq6.mp3`,
    kannadaSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq6-kannada.mp3`,
    tamilSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq7-tamil.m4a`,
    teluguSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq7-telugu.mp3`,
    marathiSoundFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq7-marathi.mp3`,
  },
];

export const InteractiveFaqs = [
  {
    question:
      "Will Turno help me get subsidy? <span class='color'>Jaykumar, Bangalore</span>",
    //"Where do i service my vehicle?What will it cost me for maintenance every month?",
    answer:
      "Turno helped me get all subsidies for my electric cargo vehicle purchase. Turno quotation includes subsidy and registration for my EV, they will do all the documentation to help you get subsidy. Jairam, Bangalore, Mahindra Treo Zor",
    // "Servicing can be done at the nearest vehicle showroom, you will only need to service once you completed 10,000 km, the cost of servicing is minimal when compared with  diesel vehicle.",
    videoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq7.mp3`,
    kannadaVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq7-kannada.mp3`,
    tamilVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-tamil.m4a`,
    teluguVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-telugu.mp3`,
    marathiVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-marathi.mp3`,
  },

  {
    question:
      "What is the benefit of buying an electric vehicle with Turno? <span class='color'>Ramesh, Bengaluru</span>",
    //"Where do i service my vehicle?What will it cost me for maintenance every month?",
    answer:
      "Turno helped me choose the best vehicles for my business and budget, i got a lot of questions answered, Finance through Turno was fast and easy. Turno also has a resale guarantee after 3 years for my EV which is good!. Suresh, Hyderabad, OSM Rage+",
    // "Servicing can be done at the nearest vehicle showroom, you will only need to service once you completed 10,000 km, the cost of servicing is minimal when compared with  diesel vehicle.",
    videoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq7.mp3`,
    kannadaVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq7-kannada.mp3`,
    tamilVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-tamil.m4a`,
    teluguVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-telugu.mp3`,
    marathiVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-marathi.mp3`,
  },

  {
    question:
      "How can i charge at home ? <span class='color'>Prathamesh, Pune</span>",
    //"Where do i service my vehicle?What will it cost me for maintenance every month?",
    answer:
      "I charge my vehicle with a 15amp plug point from my home. Same plug point like washing machine,tv. Not too much load on electricity so good benefit, electricity bill in month end so no need for daily cash for fuel! Sumeeth, Bangalore, Piaggio Ape Electric",
    // "Servicing can be done at the nearest vehicle showroom, you will only need to service once you completed 10,000 km, the cost of servicing is minimal when compared with  diesel vehicle.",
    videoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq7.mp3`,
    kannadaVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq7-kannada.mp3`,
    tamilVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-tamil.m4a`,
    teluguVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-telugu.mp3`,
    marathiVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-marathi.mp3`,
  },

  {
    question:
      "Does Turno have exchange offer ? <span class='color'>Vivek, Chennai</span>",
    //"Where do i service my vehicle?What will it cost me for maintenance every month?",
    answer:
      "Yes, Turno has an exchange offer where you can sell your old vehicle and get a new commercial electric 3 wheeler. I asked the Turno agent to sell my vehicle and they helped me in finding a buyer. I bought OSM Rage+ in exchange for my old vehicle. Ramith, Pune",
    // "Servicing can be done at the nearest vehicle showroom, you will only need to service once you completed 10,000 km, the cost of servicing is minimal when compared with  diesel vehicle.",
    videoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq7.mp3`,
    kannadaVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq7-kannada.mp3`,
    tamilVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-tamil.m4a`,
    teluguVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-telugu.mp3`,
    marathiVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-marathi.mp3`,
  },

  {
    question:
      "How much cost can i save with electric vehicle? <span class='color'>Jaiprakash, Mumbai</span>",
    //"Where do i service my vehicle?What will it cost me for maintenance every month?",
    answer:
      "Daily savings of 700-800 compared to Diesel, monthly i am saving 24,000 Rupees after switching to electric. You can run full day for local delivery business. Running cost lower than diesel, CNG, also easy to drive with zero sound and pollution. The price of the electric auto is good but negotiate with Turno for a good deal. . Deepak, Pune",
    // "Servicing can be done at the nearest vehicle showroom, you will only need to service once you completed 10,000 km, the cost of servicing is minimal when compared with  diesel vehicle.",
    videoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq7.mp3`,
    kannadaVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq7-kannada.mp3`,
    tamilVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-tamil.m4a`,
    teluguVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-telugu.mp3`,
    marathiVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-marathi.mp3`,
  },

  {
    question:
      "How much is the maintenance cost of electric vehicle ? <span class='color'>Mahesh, Bengaluru</span>",
    //"Where do i service my vehicle?What will it cost me for maintenance every month?",
    answer:
      "Zero maintenance cost, I use Piaggio electric for one year and till now no major maintenance. One problem with headlight, got it fixed at workshop easily. Shyam, Pune",
    // "Servicing can be done at the nearest vehicle showroom, you will only need to service once you completed 10,000 km, the cost of servicing is minimal when compared with  diesel vehicle.",
    videoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq7.mp3`,
    kannadaVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq7-kannada.mp3`,
    tamilVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-tamil.m4a`,
    teluguVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-telugu.mp3`,
    marathiVideoFile: `${process.env.NEXT_PUBLIC_AUDIO_LINK}faq1-marathi.mp3`,
  },
];

export const VideoSliderFaqs = [
  {
    id: 1,
    videoFile: `${process.env.NEXT_PUBLIC_VIDEO_LINK}Hindi/Cost+Savings_Hindi.mp4`,
    kannadaVideoFile: `${process.env.NEXT_PUBLIC_VIDEO_LINK}Kannada/Cost+Savings_Kannada.mp4`,
    tamilVideoFile: `${process.env.NEXT_PUBLIC_VIDEO_LINK}Tamil/Cost+Savings_Tamil+(1).mp4`,
    teluguVideoFile: `${process.env.NEXT_PUBLIC_VIDEO_LINK}Telugu/Cost+Savings_Telugu.mp4`,
    thumbnailUrl: `${process.env.NEXT_PUBLIC_CDN_LINK}FAQImages/Askquestion/png/Deenadayala.jpg`,
  },

  {
    id: 2,
    videoFile: `${process.env.NEXT_PUBLIC_VIDEO_LINK}Hindi/Load+Capacity_Hindi.mp4`,
    kannadaVideoFile: `${process.env.NEXT_PUBLIC_VIDEO_LINK}Kannada/Load+Capacity_Kannada.mp4`,
    tamilVideoFile: `${process.env.NEXT_PUBLIC_VIDEO_LINK}Tamil/Load+Capacity_Tamil.mp4`,
    teluguVideoFile: `${process.env.NEXT_PUBLIC_VIDEO_LINK}elugu/Load+Capacity_Telugu.mp4`,
    thumbnailUrl: `${process.env.NEXT_PUBLIC_CDN_LINK}FAQImages/Askquestion/png/Parthasarthi.jpg`,
  },
  {
    id: 3,
    videoFile: `${process.env.NEXT_PUBLIC_VIDEO_LINK}Hindi/Range+Capacity_Hindi.mp4`,
    kannadaVideoFile: `${process.env.NEXT_PUBLIC_VIDEO_LINK}Kannada/Range+Capacity_Kannada.mp4`,
    tamilVideoFile: `${process.env.NEXT_PUBLIC_VIDEO_LINK}Tamil/Range+Capacity_Tamil.mp4`,
    teluguVideoFile: `${process.env.NEXT_PUBLIC_VIDEO_LINK}Telugu/Range+Capacity_Telugu.mp4`,
    thumbnailUrl: `${process.env.NEXT_PUBLIC_CDN_LINK}FAQImages/Askquestion/png/Kabmle.jpg`,
  },
  {
    id: 4,
    videoFile: `${process.env.NEXT_PUBLIC_VIDEO_LINK}Hindi/Uphill+Performance_Hindi.mp4`,
    kannadaVideoFile: `${process.env.NEXT_PUBLIC_VIDEO_LINK}Kannada/Uphill+Performance_Kannada.mp4`,
    tamilVideoFile: `${process.env.NEXT_PUBLIC_VIDEO_LINK}Tamil/Uphill+Performance_Tamil.mp4`,
    teluguVideoFile: `${process.env.NEXT_PUBLIC_VIDEO_LINK}Telugu/Uphill+Performance_Telugu.mp4`,
    thumbnailUrl: `${process.env.NEXT_PUBLIC_CDN_LINK}FAQImages/Askquestion/png/Rudhresh.jpg`,
  },
];

export const HomePageFAQ = [
  {
    id: 1,
    question:
      "What is Turno.club and why should I buy my goods electric vehicle through Turno?",
    answer:
      "Turno is a one-stop destination to buy all best-selling commercial electric vehicles available in the market. We help buyers to choose the best electric 3-wheeler vehicle for their business along with easy financing and assured resale.",
  },
  {
    id: 2,
    question: "Does Turno have an exchange offer?",
    answer:
      "Yes, Turno has an exchange offer where you can sell your old vehicle and get a new commercial electric 3-wheeler. To know more about our exchange offer, contact us on 08047842233.",
  },
  {
    id: 3,
    question:
      "What are the financial schemes Turno provides for three-wheeler electric auto?",
    answer:
      "Turno gives the lowest downpayment of ₹49,999 on commercial electric vehicles with a flat interest rate of 10.5%. We also provide EV financing with low processing fees and zero hidden charges.",
  },
  {
    id: 4,
    question: "What is the price of Turno 3-wheeler EV?",
    answer:
      "The price of Turno 3-wheeler ranges from ₹3.9 lakhs to ₹4.5 lakhs.",
  },
  {
    id: 5,
    question: "What is the Turno warranty on an electric vehicle?",
    answer:
      "We provide 3 years of warranty on the electric vehicle battery. Also, if you wish to resell your vehicle to Turno, we provide a guaranteed resale value of up to ₹1.5 lakh or 40% of the ex-showroom price whichever is lower after 3 years.",
  },
  {
    id: 6,
    question:
      "Will Turno help me get a Government subsidy on electric cargo vehicles?",
    answer:
      "Absolutely! The quotation from Turno includes subsidies and registration charges for EVs. We also provide assistance in documentation to avail of the electric vehicle subsidy.",
  },
  {
    id: 7,
    question:
      "How will I find the battery electric vehicle that suits my business?",
    answer:
      "In order to find the EV that suits your business, you should consider the desired load capacity, range, and budget. You can also contact Turno EV experts to help you find the best vehicle for your business.",
  },
  {
    id: 8,
    question:
      "How long will it take to deliver my load electric auto once I pay the booking amount?",
    answer:
      "Generally, it takes us 5 days to deliver the vehicle after the booking amount is paid.",
  },
  {
    id: 9,
    question:
      "I don’t know my credit score. Can I still get the financing for my goods electric vehicle?",
    answer:
      "At Turno, we provide assured on-road financing for electric vehicles. We also provide instant credit eligibility checks. To know more about EV financing, kindly contact Turno EV experts today at 080 4784 2233.",
  },
  {
    id: 10,
    question:
      "What is the longest and shortest possible tenure of my battery electric vehicle loan?",
    answer:
      "With Turno, you can avail EV financing for a tenure of 12 to 48 months.",
  },
];

export const VehiclePageFAQ = [
  {
    id: 1,
    question: "How to charge a three wheeler electric vehicle at home?",
    answer:
      "You can charge your electric vehicle using a 15AMP socket. Such sockets are generally used for geysers and washing machines at home. ",
  },
  {
    id: 2,
    question: "How much can a user save with a 3w electric vehicle? ",
    answer:
      "With electric vehicles, you can save on your daily expenses in running cost and the maintenance cost other than the subsidy received from the government. Running cost of EV -  ₹1/km Maintenance cost of EV -  ₹1000/year ",
  },
  {
    id: 3,
    question:
      "How much is the maintenance cost of a commercial electric 3 wheeler? ",
    answer:
      "On an average, the maintenance of an EV costs ₹1000/year. EVs have a much simpler engine with few moving parts that make the maintenance cost significantly low. What you need to take care of are- the brakes, steering, suspension, electric motor, and battery.",
  },
  {
    id: 4,
    question: "What is range anxiety with commercial electric vehicles?",
    answer:
      "Range anxiety is the fear that the electric vehicles will not have enough battery to reach the destination and you will be stuck in remote places. Load electric vehicles have a good range up to 100+ km with full load. Also, you can monitor the battery range in real time in your Turno app.",
  },
  {
    id: 5,
    question: "Are electric vehicles safe for commercial use in India?",
    answer:
      "Yes, electric vehicles are safe and the most economical option for commercial use in India.",
  },
  {
    id: 6,
    question:
      "How much time does it take to charge the electric vehicle and where can it be charged?",
    answer:
      "It takes 3 to 3.5 hours to fully charge three wheeler electric vehicles. You can charge your electric auto at home or at charging stations closest to you. The location information about charging stations for commercial electric vehicles is available on google maps as well.",
  },
  {
    id: 7,
    question: "Is there a subsidy available on electric commercial vehicles?",
    answer:
      "Yes, subsidies are available on electric luggage vehicles. However, please be noted that it may vary from state to state. The subsidy is already included in the on-road price of the electric vehicles. For more information or to know the exact subsidy amount by vehicle model and location, contact us today.",
  },
  {
    id: 8,
    question: "Can I load up the electric vehicle beyond the load limit?",
    answer:
      "Yes, you can increase the load beyond the limit but it is not recommended. In case you load more than the limit in the electric auto, the battery will discharge faster. So, we recommend following the instructions of the manufacturer.",
  },
  {
    id: 9,
    question:
      "Where do I service my three wheeler electric vehicle and what will it cost me?",
    answer:
      "Servicing can be done at the nearest electric vehicle showroom. on an average, the maintenance cost of EV is 1000/per year.",
  },
  {
    id: 10,
    question: "What are the standard features for a commercial electric auto?",
    answer:
      "A commercial electric auto generally has a body, battery and motor. The commercial electric vehicles  have a standard maximum speed of 50 kmph and have boost mode to temporarily increase the speed when the load is higher.  ",
  },
  {
    id: 11,
    question: "Is there an option to take a test drive of electric vehicles?",
    answer:
      "Yes, you can opt for a test drive for an electric luggage auto at the vehicle showroom as well as a doorstep test drive is also available at your convenient time and location. Visit https://www.turno.club/vehicles to book a slot.",
  },
  {
    id: 12,
    question: "What is the range of an electric auto ?",
    answer:
      "An electric luggage auto can go up to 100 km in one full charge. The range may vary depending on the vehicle variant as well as load carried on the electric vehicle. If the load is higher than the recommended limit, the battery may discharge faster than usual.",
  },
  {
    id: 13,
    question:
      "Is there an option to book a test drive before buying an electric vehicle?",
    answer:
      "Yes, Turno provides test drives for three wheeler electric vehicles. You can contact Turno CEV experts to book a doorstep test drive at your convenient location and time or else you can visit the nearest showroom.",
  },
  {
    id: 14,
    question: "Do electric autos come with warranties?",
    answer:
      "Yes, electric luggage vehicles come with battery warranty from vehicle manufacturers. Most of the commercial electric vehicles come with a warranty of 3 years or up to 80,000 km.",
  },
  {
    id: 15,
    question:
      "How can I enhance the driving range of my electric carrier auto?",
    answer:
      "Driving smoothly is the key to increasing the range of your vehicle. Avoiding sudden acceleration and maintaining the tires in good condition can improve the driving range of your electric car.",
  },
  {
    id: 16,
    question: "Will my EV auto lose charge when it’s parked?",
    answer:
      "An electric vehicle does not lose charge when it’s parked. If you park your vehicle for a longer duration, you may experience a battery drain by a few percent but will never run out of charge because of long parking time.",
  },
  {
    id: 17,
    question: "What kind of maintenance do electric vehicles need?",
    answer:
      "Electric cars require less maintenance compared to a fuel-powered vehicle. What you need to take care of are- the brakes, steering and suspension, the electric motor and the battery. ",
  },
  {
    id: 18,
    question: "Can I drive my electric vehicle during rains?",
    answer:
      "Yes, you can drive your electric vehicle during rains without any worries.",
  },
  {
    id: 19,
    question: "Are EVs safe?",
    answer:
      "All EV batteries go through rigorous testing procedures at National Accreditation Board for Testing and Calibration Laboratories (NABL) certified labs. Certification agencies in India like ARAI, ICAT certify EV and chargers. Thus, it is safe to drive an EV.",
  },
  {
    id: 20,
    question: "Does an electric vehicle work in cold weather ?",
    answer:
      "The cold weather may impact your battery range if it is 0 degree or lesser. Considering the weather conditions of India, you can be assured of no battery drainage due to cold weather.",
  },
  {
    id: 21,
    question: "Are electric autos more expensive than fuel-powered autos?",
    answer:
      "No, electric autos and fuel-powered autos need a similar investment, which is nearly Rs. 2 lakhs. However, the total cost of owning an EV is less than half of owning a fuel-powered vehicle while calculated for three years. To know more about the total cost of owing, kindly contact Turno EV experts.",
  },
  {
    id: 22,
    question:
      "Should I consider buying insurance for my commercial electric auto?",
    answer:
      "We highly recommend buying insurance for your EV as the Motor Vehicles Act, 1988, mentions that third-party insurance is compulsory. Having an electric commercial insurance policy will help you remain on the secure side of the law.",
  },
];

export const PiaggioMaintainenceFAQ = [
  {
    id: 1,
    question: "What is the recommended service interval for first service?",
    answer:
      "The recommended service interval for first service is  3 months or 5000 kms",
  },
  {
    id: 2,
    question: "Does the manufacturer provide any free services?",
    answer: "Yes, all  Piaggio Ape Electrik buyers will get 10 free services.",
  },
  {
    id: 3,
    question: "How do I schedule servicing for my vehicle?",
    answer:
      "The dealer will give a reminder call 2-5 days ahead of the scheduled service date.  We strongly recommend getting the servicing done on time as if you can not turn up, it will result in lapsing your free service.",
  },
  {
    id: 4,
    question:
      "Can I get Road Side Assistance(RSA) for my Piaggio Ape Electrik?",
    answer:
      "Yes, RSA is available. To arrange the service, you can directly call the dealer or call us for a faster resolution.",
  },
  {
    id: 5,
    question: "What are the steps of servicing my vehicle?",
    answer:
      "The steps of vehicle servicing are as follows Charge the battery up to one block and then check with them multimeter to see whether the cells are being charged enough. Check the breaks. Check if the break oil needs to be changed. Air pressure wash",
  },
  {
    id: 6,
    question: "How long will it take for one servicing?",
    answer:
      "It may take a max of 8 hours for complete servicing. However, if there are any other issues with the vehicle, it may take longer.",
  },
];

export const MahindraMaintainenceFAQ = [
  {
    id: 1,
    question: "What is the recommended service interval for first service?",
    answer:
      "The recommended service interval is 2000 kms or once in every 3 months.",
  },
  {
    id: 2,
    question: "Does the manufacturer provide any free services?",
    answer:
      "First three services of your Mahindra Treo Zor along with the labor charges are free. After three servicings,  you will be charged for brake oil change and general battery check up.",
  },

  {
    id: 3,
    question: "How do I schedule servicing for my vehicle?",
    answer:
      "To book servicing of your vehicle, you can contact the service center directly. Otherwise, you can also reach out to the team and we can schedule it on your behalf.",
  },
  {
    id: 4,
    question: "Can I get Roadside Assistance(RSA) for my Mahindra Treo Zor?",
    answer:
      "Yes, RSA is available. To arrange the service, you can directly call the dealer or call us for a faster resolution. Please note that the RSA services costs ₹1,260rs / year",
  },
  {
    id: 5,
    question: "What are the steps of servicing my vehicle?",
    answer:
      "The steps of vehicle servicing are as follows- Charge the battery up to one block and then check with them multimeter to see whether the cells are being charged enough. Check the breaks. Check if the break oil needs to be changed. Air pressure wash",
  },
  {
    id: 6,
    question: "How long will it take for one servicing?",
    answer:
      "Usually the servicing takes a day (10am - 5pm) and in case of any change in wires or any tech upgrade, it will take longer.",
  },
  {
    id: 7,
    question: "In case of vehicle breakdown, can I get towing services?",
    answer:
      "Yes, towing services are available for this vehicle. Please be noted that it is a paid service. ",
  },
];

export const ETRIOMaintainanceFAQs = [
  {
    id: 1,
    question: "What is the recommended service interval for servicing E-Trio?",
    answer:
      "As per Service Schedule defined in the Owners Handbook - the 1st service of this electric three wheeler is due at 2000 km , the 2nd service is due at 5000 km & 3rd is due at 10000 km; thereafter a service is due every 10000 km.",
  },

  {
    id: 2,
    question: "Does the manufacturer provide any free services for E-Trio?",
    answer:
      "This electric gadi provides 3 free services - 2000 km or 2 Month; 5000 km or 3 Month & 10000 Km or 6 Month – whichever is earlier. The vouchers for free services will be available in the Owners Handbook that you will receive at the time of purchasing this electric tempo . For any assistance required for scheduling the servicing, you can reach out to Turno.",
  },

  {
    id: 3,
    question: "How do I schedule servicing for E-Trio?",
    answer:
      "This electric three wheeler, E-trio has a centralized service model. You can report any issues/service requests with Area Service Managers - who in coordination with HQ - shall schedule service requests. Otherwise, you can also reach out to Turno for scheduling your vehicle servicing.",
  },

  {
    id: 4,
    question: "What are the steps of servicing E-Trio",
    answer: `Step 1: Contact Turno or Area Service Manager to schedule your service<br/>
      Step 2: Share vehicle details including vehicle registration, Kms run, and any other specific issues that need addressing. Dealer/ ASM will confirm the location, date, and time for your service.<br/>
      Step 3: Bring your electric gadi for service at the specified location<br/>
      Step 4: The service team will inspect the vehicle and confirm the estimated cost of the service<br/>
      Step 5: Post approval the service team will carry out the service of the vehicle<br/>
      Step 6: On Completion of the service, the team will generate the invoice<br/>
      Step 7: Upon receipt of the payment your electric gadi will be prepared for delivery
      `,
  },
  {
    id: 5,
    question:
      "In case of an E-Trio vehicle breakdown, can I get towing services?",
    answer:
      "Local Etrio Area Service Managers help in getting towing done. ETRIO & Customer will bear the cost in a 50:50 ratio in case of technical breakdown. In case of an accident, the towing charges of this electric tempo shall be on the customer's account. ",
  },
];

export const FinancePageFAQ = [
  {
    id: 1,
    question: "What is EV Three Wheeler Loan application process?",
    answer:
      "You can apply for your EV Three Wheeler Loan through our online banking services via Turno Website. Log in to your Turno app and find out more. However, applying online via Turno is faster and saves resources.",
  },
  {
    id: 2,
    question: "Can I get 100% funding for Buying a EV Three Wheeler?",
    answer:
      "Turno offers upto 85-90% on-road funding. It is applicable for both existing customers as well as new customers based on their  profile.",
  },
  {
    id: 3,
    question:
      "What’s the minimum credit score I need to get financing for an EV Three Wheeler?",
    answer:
      "At Turno, we haven’t specified a minimum Credit Score for getting a EV Three Wheeler loan. However, a lower Credit Score may lead to the approval of lower loan amounts. Make sure your Credit Score is more than 650, which is generally considered good.",
  },
  {
    id: 4,
    question:
      "What are the minimum and maximum tenures of an EV Three Wheeler Loan?",
    answer:
      "Turno offers flexible tenures on their EV three wheeler Loans product. The minimum and maximum tenure are from 12 months to 48 months.",
  },
  {
    id: 5,
    question: "Do I have the option to pre-pay the entire loan amount?",
    answer:
      "Yes, you can pre-pay the loan any time after a lock in period of three months at a nominal charge.",
  },
  {
    id: 6,
    question: "Do I need a guarantor?",
    answer:
      "No, generally, loan guarantor is not required to avail an EV loan. However, in case you don't meet the Bank's norms then you may be required to have a guarantor surety. A guarantor becomes a co-endorser and becomes liable to repay the loan  in event of default. ",
  },
  {
    id: 7,
    question: "How long will it take to process the loan?",
    answer:
      "Loan processing and disbursement will take at least 5 working days post submission of all requisite documents and information.",
  },
  {
    id: 8,
    question: "What are different modes of loan repayment ?",
    answer:
      "Repayments are enabled through NACH. If the repayment through these instruments is not cleared, there are alternate digital repayment modes.",
  },
  {
    id: 9,
    question: "How can I contact Turno post disbursement of loan?",
    answer:
      "Customers  can raise his request digitally or reach out to sales@turno.club",
  },
  {
    id: 10,
    question: "Is there a processing fee charged by Turno? And how much?",
    answer:
      "There is a Service charge of Rs.7,500, which covers everything including Stamp duties, Legal fees, etc.",
  },
  {
    id: 11,
    question: "Are there any additional benefits of taking Finance from Turno?",
    answer:
      "Turno offers EV buy-back option after three years for a maximum of upto INR 1,50,000. Turno also provides a Telematics App where owners can track their vehicle, battery life and usage, charging details among others. Owners can also track their EMI and loan balance directly.",
  },
];

export const BangaloreFAQ = [
  {
    id: 1,
    question:
      "What are the best cargo electric vehicles available in Bangalore?",
    answer:
      "Some of the best cargo electric vehicles available in Bangalore are: Piaggio Ape Electrik, Mahindra Treo Zor, Etrio Touro Max+, OSM Rage+",
  },
  {
    id: 2,
    question:
      "What is the price range of three wheeler electric cargo vehicles in Bangalore?",
    answer:
      "Based on the brand, model, and specifications electric auto prices in Bangalore can vary between 3.5 lakhs to 4.5 lakhs. However,  you can buy any three wheeler electric tempo with easy EMIs, when you purchase it with Turno.",
  },
  {
    id: 3,
    question:
      "Which is the most popular place to buy an electric three wheeler cargo vehicle in Bangalore?",
    answer:
      "Turno is one of the most popular Cargo EV OEM aggregators in Bangalore working in collaboration with OEM and Dealerships that helps in choosing the best suited electric three wheeler cargo vehicle for your business. Along with this we provide the following benefits:, Best offers on electric three wheeler loading auto, Fastest delivery in just 5 days, Finance at 12.49% which is the lowest interest rates in the market, Guaranteed resale value of up to 1.5 lakhs etc.",
  },
  {
    id: 4,
    question:
      "How can I choose the best electric gadi for my business in Bangalore?",
    answer:
      "There are multiple options to choose from if you are planning to buy an electric gadi in Bangalore. You can talk with Turno Experts in Bangalore, we help you make an informed decision of buying an electric three wheeler cargo vehicle based on vehicle and business requirement of  load, range, capacity etc. Turno experts also provide guidance in clarifying false myths around EV adoption and benefits of EV compared to other ICE vehicles. In addition Turno also provides a test drive option as per user convenient location and time.",
  },
  {
    id: 5,
    question:
      "Where can I charge my 3 wheeler electric goods vehicle in Bangalore?",
    answer:
      " Charging a 3 wheeler electric goods vehicle is very easy as you can charge it at home using a 15 AMP power socket. It can also be charged at fast charging stations available at fuel pumps across Bangalore and also at BESCOM Charging Stations. Also Turno gives you a free mobile app to track the battery and health of your electric gadi if you purchase the electric gadi from Turno.",
  },
];

export const ChennaiFAQ = [
  {
    id: 1,
    question: "What are the best cargo electric vehicles available in Chennai?",
    answer:
      "Some of the best cargo electric vehicles available in Chennai are: Piaggio Ape Electrik, Mahindra Treo Zor, Etrio Touro Max+, OSM Rage+",
  },
  {
    id: 2,
    question:
      "What is the price range of three wheeler electric cargo vehicles in Chennai?",
    answer:
      "Based on the brand, model, and specifications electric auto prices in Chennai can vary between 3.5 lakhs to 4.5 lakhs. However,  you can buy any three wheeler electric tempo with easy EMIs, when you purchase it with Turno.",
  },
  {
    id: 3,
    question:
      "Which is the most popular place to buy an electric three wheeler cargo vehicle in Chennai?",
    answer:
      "Turno is one of the most popular Cargo EV OEM aggregators in Chennai working in collaboration with OEM and Dealerships that helps in choosing the best suited electric three wheeler cargo vehicle for your business. Along with this we provide the following benefits:, Best offers on electric three wheeler loading auto, Fastest delivery in just 5 days, Finance at 12.49% which is the lowest interest rates in the market, Guaranteed resale value of up to 1.5 lakhs etc.",
  },
  {
    id: 4,
    question:
      "How can I choose the best electric gadi for my business in Chennai?",
    answer:
      "There are multiple options to choose from if you are planning to buy an electric gadi in Chennai. You can talk with Turno Experts in Chennai, we help you make an informed decision of buying an electric three wheeler cargo vehicle based on vehicle and business requirement of  load, range, capacity etc. Turno experts also provide guidance in clarifying false myths around EV adoption and benefits of EV compared to other ICE vehicles. In addition Turno also provides a test drive option as per user convenient location and time.",
  },
  {
    id: 5,
    question:
      "Where can I charge my 3 wheeler electric goods vehicle in Chennai?",
    answer:
      " Charging a 3 wheeler electric goods vehicle is very easy as you can charge it at home using a 15 AMP power socket. It can also be charged at fast charging stations available at fuel pumps across Chennai and also at BESCOM Charging Stations. Also Turno gives you a free mobile app to track the battery and health of your electric gadi if you purchase the electric gadi from Turno.",
  },
];

export const DelhiAndNCRFAQ = [
  {
    id: 1,
    question:
      "What are the best cargo electric vehicles available in Delhi And NCR?",
    answer:
      "Some of the best cargo electric vehicles available in Delhi And NCR are: Piaggio Ape Electrik, Mahindra Treo Zor, Etrio Touro Max+, OSM Rage+",
  },
  {
    id: 2,
    question:
      "What is the price range of three wheeler electric cargo vehicles in Delhi And NCR?",
    answer:
      "Based on the brand, model, and specifications electric auto prices in Delhi And NCR can vary between 3.5 lakhs to 4.5 lakhs. However,  you can buy any three wheeler electric tempo with easy EMIs, when you purchase it with Turno.",
  },
  {
    id: 3,
    question:
      "Which is the most popular place to buy an electric three wheeler cargo vehicle in Delhi And NCR?",
    answer:
      "Turno is one of the most popular Cargo EV OEM aggregators in Delhi And NCR working in collaboration with OEM and Dealerships that helps in choosing the best suited electric three wheeler cargo vehicle for your business. Along with this we provide the following benefits:, Best offers on electric three wheeler loading auto, Fastest delivery in just 5 days, Finance at 12.49% which is the lowest interest rates in the market, Guaranteed resale value of up to 1.5 lakhs etc.",
  },
  {
    id: 4,
    question:
      "How can I choose the best electric gadi for my business in Delhi And NCR?",
    answer:
      "There are multiple options to choose from if you are planning to buy an electric gadi in Delhi And NCR. You can talk with Turno Experts in Delhi And NCR, we help you make an informed decision of buying an electric three wheeler cargo vehicle based on vehicle and business requirement of  load, range, capacity etc. Turno experts also provide guidance in clarifying false myths around EV adoption and benefits of EV compared to other ICE vehicles. In addition Turno also provides a test drive option as per user convenient location and time.",
  },
  {
    id: 5,
    question:
      "Where can I charge my 3 wheeler electric goods vehicle in Delhi And NCR?",
    answer:
      " Charging a 3 wheeler electric goods vehicle is very easy as you can charge it at home using a 15 AMP power socket. It can also be charged at fast charging stations available at fuel pumps across Delhi And NCR and also at BESCOM Charging Stations. Also Turno gives you a free mobile app to track the battery and health of your electric gadi if you purchase the electric gadi from Turno.",
  },
];

export const HyderabadFAQ = [
  {
    id: 1,
    question:
      "What are the best cargo electric vehicles available in Hyderabad?",
    answer:
      "Some of the best cargo electric vehicles available in Hyderabad are: Piaggio Ape Electrik, Mahindra Treo Zor, Etrio Touro Max+, OSM Rage+",
  },
  {
    id: 2,
    question:
      "What is the price range of three wheeler electric cargo vehicles in Hyderabad?",
    answer:
      "Based on the brand, model, and specifications electric auto prices in Hyderabad can vary between 3.5 lakhs to 4.5 lakhs. However,  you can buy any three wheeler electric tempo with easy EMIs, when you purchase it with Turno.",
  },
  {
    id: 3,
    question:
      "Which is the most popular place to buy an electric three wheeler cargo vehicle in Hyderabad?",
    answer:
      "Turno is one of the most popular Cargo EV OEM aggregators in Hyderabad working in collaboration with OEM and Dealerships that helps in choosing the best suited electric three wheeler cargo vehicle for your business. Along with this we provide the following benefits:, Best offers on electric three wheeler loading auto, Fastest delivery in just 5 days, Finance at 12.49% which is the lowest interest rates in the market, Guaranteed resale value of up to 1.5 lakhs etc.",
  },
  {
    id: 4,
    question:
      "How can I choose the best electric gadi for my business in Hyderabad?",
    answer:
      "There are multiple options to choose from if you are planning to buy an electric gadi in Hyderabad. You can talk with Turno Experts in Hyderabad, we help you make an informed decision of buying an electric three wheeler cargo vehicle based on vehicle and business requirement of  load, range, capacity etc. Turno experts also provide guidance in clarifying false myths around EV adoption and benefits of EV compared to other ICE vehicles. In addition Turno also provides a test drive option as per user convenient location and time.",
  },
  {
    id: 5,
    question:
      "Where can I charge my 3 wheeler electric goods vehicle in Hyderabad?",
    answer:
      " Charging a 3 wheeler electric goods vehicle is very easy as you can charge it at home using a 15 AMP power socket. It can also be charged at fast charging stations available at fuel pumps across Hyderabad and also at BESCOM Charging Stations. Also Turno gives you a free mobile app to track the battery and health of your electric gadi if you purchase the electric gadi from Turno.",
  },
];

export const MumbaiFAQ = [
  {
    id: 1,
    question: "What are the best cargo electric vehicles available in Mumbai?",
    answer:
      "Some of the best cargo electric vehicles available in Mumbai are: Piaggio Ape Electrik, Mahindra Treo Zor, Etrio Touro Max+, OSM Rage+",
  },
  {
    id: 2,
    question:
      "What is the price range of three wheeler electric cargo vehicles in Mumbai?",
    answer:
      "Based on the brand, model, and specifications electric auto prices in Mumbai can vary between 3.5 lakhs to 4.5 lakhs. However,  you can buy any three wheeler electric tempo with easy EMIs, when you purchase it with Turno.",
  },
  {
    id: 3,
    question:
      "Which is the most popular place to buy an electric three wheeler cargo vehicle in Mumbai?",
    answer:
      "Turno is one of the most popular Cargo EV OEM aggregators in Mumbai working in collaboration with OEM and Dealerships that helps in choosing the best suited electric three wheeler cargo vehicle for your business. Along with this we provide the following benefits:, Best offers on electric three wheeler loading auto, Fastest delivery in just 5 days, Finance at 12.49% which is the lowest interest rates in the market, Guaranteed resale value of up to 1.5 lakhs etc.",
  },
  {
    id: 4,
    question:
      "How can I choose the best electric gadi for my business in Mumbai?",
    answer:
      "There are multiple options to choose from if you are planning to buy an electric gadi in Mumbai. You can talk with Turno Experts in Mumbai, we help you make an informed decision of buying an electric three wheeler cargo vehicle based on vehicle and business requirement of  load, range, capacity etc. Turno experts also provide guidance in clarifying false myths around EV adoption and benefits of EV compared to other ICE vehicles. In addition Turno also provides a test drive option as per user convenient location and time.",
  },
  {
    id: 5,
    question:
      "Where can I charge my 3 wheeler electric goods vehicle in Mumbai?",
    answer:
      " Charging a 3 wheeler electric goods vehicle is very easy as you can charge it at home using a 15 AMP power socket. It can also be charged at fast charging stations available at fuel pumps across Mumbai and also at BESCOM Charging Stations. Also Turno gives you a free mobile app to track the battery and health of your electric gadi if you purchase the electric gadi from Turno.",
  },
];

export const PuneFAQ = [
  {
    id: 1,
    question: "What are the best cargo electric vehicles available in Pune?",
    answer:
      "Some of the best cargo electric vehicles available in Pune are: Piaggio Ape Electrik, Mahindra Treo Zor, Etrio Touro Max+, OSM Rage+",
  },
  {
    id: 2,
    question:
      "What is the price range of three wheeler electric cargo vehicles in Pune?",
    answer:
      "Based on the brand, model, and specifications electric auto prices in Pune can vary between 3.5 lakhs to 4.5 lakhs. However,  you can buy any three wheeler electric tempo with easy EMIs, when you purchase it with Turno.",
  },
  {
    id: 3,
    question:
      "Which is the most popular place to buy an electric three wheeler cargo vehicle in Pune?",
    answer:
      "Turno is one of the most popular Cargo EV OEM aggregators in Pune working in collaboration with OEM and Dealerships that helps in choosing the best suited electric three wheeler cargo vehicle for your business. Along with this we provide the following benefits:, Best offers on electric three wheeler loading auto, Fastest delivery in just 5 days, Finance at 12.49% which is the lowest interest rates in the market, Guaranteed resale value of up to 1.5 lakhs etc.",
  },
  {
    id: 4,
    question:
      "How can I choose the best electric gadi for my business in Pune?",
    answer:
      "There are multiple options to choose from if you are planning to buy an electric gadi in Pune. You can talk with Turno Experts in Pune, we help you make an informed decision of buying an electric three wheeler cargo vehicle based on vehicle and business requirement of  load, range, capacity etc. Turno experts also provide guidance in clarifying false myths around EV adoption and benefits of EV compared to other ICE vehicles. In addition Turno also provides a test drive option as per user convenient location and time.",
  },
  {
    id: 5,
    question: "Where can I charge my 3 wheeler electric goods vehicle in Pune?",
    answer:
      " Charging a 3 wheeler electric goods vehicle is very easy as you can charge it at home using a 15 AMP power socket. It can also be charged at fast charging stations available at fuel pumps across Pune and also at BESCOM Charging Stations. Also Turno gives you a free mobile app to track the battery and health of your electric gadi if you purchase the electric gadi from Turno.",
  },
];

// vehicle page paq

export const vehiclePagefaq = [
  {
    id: 1,
    question:
      "What are the popular brands of Electric Commercial Vehicles that Turno offers?",
    answer:
      "Turno offers a wide range of Electric Commercial Vehicles from popular brands like Mahindra, Piaggio, OSM, Euler, Etrio, and Altigreen.",
  },
  {
    id: 2,
    question: "How do electric goods vehicles work?",
    answer:
      "Electric vehicles have electric motors that convert electrical energy into mechanical energy, which powers the wheels. The energy to drive the vehicle comes from an onboard battery, which is recharged from the power grid.",
  },
  {
    id: 3,
    question: "What is the Price of an electric cargo vehicle?",
    answer:
      "The price of a cargo electric auto in India ranges from ₹3.9 lakh to ₹4.5 lakh, depending on the brand, model, and features. The price of the vehicle also may differ depending on the region of the purchase. To know the exact amount you need to pay for any specific electric cargo vehicle in your region, please contact us.",
  },
  {
    id: 4,
    question:
      "What are the various uses of electric commercial vehicles in India?",
    answer:
      "In India, commercial electric autos are used for agile goods movement, intercity and intracity transportation, e-commerce, FMCG, parcel, water can distribution, milk can transportation etc.",
  },
  {
    id: 5,
    question: "What are the best electric cargo vehicle company in India?",
    answer:
      "The top cargo electric vehicle companies in India are Mahindra, Piaggio, Greaves, Altigreen, Euler, Etrio, OSM and TATA. However, the best vehicle for any business depends on the required load capacity and range. To find the best vehicle for your business, please connect with EV experts at Turno.",
  },
  {
    id: 6,
    question:
      "What is the maximum load capacity of an electric loading vehicle?",
    answer:
      "The load capacity of an electric loading vehicle ranges from 500 kg to 1.2 tons.",
  },
  {
    id: 7,
    question: "What is the Kerb Weight of an electric loading vehicle?",
    answer:
      "The kerb weight is the vehicle’s weight without people or extra cargo. The kerb weight varies for different vehicles and usually ranges between 450-600 kg.",
  },
  {
    id: 8,
    question: "How far can electric vehicles travel on a single charge?",
    answer:
      "Goods electric vehicles can travel up to 130 km on a single charge. However, the range of load EVs may differ from brand to brand and the load you are carrying.",
  },
  {
    id: 9,
    question:
      "Can I take a test drive of an electric commercial vehicle before purchasing it?",
    answer:
      "Yes, you can take a test drive of an electric commercial vehicle before purchasing it. With Turno, you can opt for a free home test drive or take a test drive at our showroom.",
  },
  {
    id: 10,
    question: "What are the advantages of electric goods vehicles?",
    answer:
      "Electric goods vehicles are much cheaper to operate when compared with similar diesel/CNG vehicles. Besides, it produces zero emissions, no noise pollution, and has better acceleration. Goods electric vehicles are an excellent option for congested and busy roads.",
  },
  {
    id: 11,
    question:
      "Do I get power steering in electric three-wheeler goods vehicles?",
    answer:
      "No, electric goods vehicles do not have power steering, they come with handlebar steering.",
  },
  {
    id: 12,
    question: "What type of battery is used in electric cargo vehicles?",
    answer:
      "Most of today's electric cargo vehicles use lithium-ion batteries. These batteries are best for EVs and they have high energy storage, low self-discharge, fast charging, water and dust resistance, etc.",
  },
  {
    id: 13,
    question: "How to buy electric commercial vehicles?",
    answer:
      "You can buy electric vehicles by visiting brand showrooms. However, if you want to find all top EV brands in one place, please visit Turno, we are a one-stop destination for best-selling top EV brands.",
  },
  {
    id: 14,
    question: "How long do electric auto batteries last?",
    answer:
      "Many factors affect the lifespan of an electric cargo vehicle battery. On average, a well-maintained EV battery can retain around 70-80% of its original capacity after 8-10 years of use.",
  },
  {
    id: 15,
    question:
      "Are there any extended warranty options available for the Electric Load Vehicles?",
    answer:
      "Yes. Extended warranties provide additional coverage beyond the standard manufacturer warranty and can offer peace of mind by protecting against unexpected repairs and expenses.",
  },
  {
    id: 16,
    question: "Can Turno help me in servicing of my vehicle?",
    answer:
      "Yes, Turno offers servicing support to its customers in various ways such as booking servicing slots on a priority basis, video call assistance for non-critical issues etc. To know more, please contact us.",
  },
  {
    id: 17,
    question: "What are Turno’s business hours and contact information?",
    answer:
      "You can contact Turno from 8 am to 10 pm on all days. Please call us at 08047482233 or drop an email at sales@turno.club.",
  },
  {
    id: 18,
    question: "What are the available colors for the Electric loading vehicle?",
    answer:
      "The available colors for electric loading vehicles vary depending on the manufacturer and model. Some of the most common colors include white, blue, green and orange.",
  },
  {
    id: 19,
    question: "What is the cost of running load electric vehicle per km?",
    answer: "The cost of running load electric vehicle is almost ₹0.9 per km.",
  },
];

export const kannadaMythFAQ = [
  {
    id: 1,
    question: "ವಾಣಿಜ್ಯ ಬಳಕೆಗೆ ಭಾರತದಲ್ಲಿ ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನಗಳು ಸುರಕ್ಷಿತವೆಂದರೆ?",
    answer:
      "ಹೌದು, ಭಾರತದಲ್ಲಿ ವಾಣಿಜ್ಯ ಬಳಕೆಗೆ ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನಗಳು ಸುರಕ್ಷಿತ ಮತ್ತು ವಾಣಿಜ್ಯ ಬಳಕೆಗೆ ಅತ್ಯಂತ ಆರ್ಥಿಕ ಆಯ್ಕೆಯಾಗಿವೆ.",
  },
  {
    id: 2,
    question:
      "ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನವನ್ನು ಚಾರ್ಜ್ ಮಾಡುವುದಕ್ಕೆ ಎಷ್ಟು ಸಮಯ ಬೇಕು ಮತ್ತು ಅದನ್ನು ಎಲ್ಲಿ ಚಾರ್ಜ್ ಮಾಡಬಹುದು?",
    answer:
      "ತ್ರಿಚಕ್ರ ವಾಹನಗಳನ್ನು ಸಂಪೂರ್ಣವಾಗಿ ಚಾರ್ಜ್ ಮಾಡಲು 3 ರಿಂದ 3.5 ಗಂಟೆಗಳು ತೆಗೆದುಕೊಳ್ಳುತ್ತದೆ. ನೀವು ನಿಮ್ಮ ಎಲೆಕ್ಟ್ರಿಕ್ ಆಟೋವನ್ನು ನೀವು ಮನೆಯಲ್ಲಿ ಅಥವಾ ನಿಮಗೆ ಹತ್ತಿರವಿರುವ ಚಾರ್ಜಿಂಗ್ ಸ್ಟೇಷನ್‌ಗಳಲ್ಲಿ ಚಾರ್ಜ್ ಮಾಡಬಹುದು. ವಾಣಿಜ್ಯ ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನಗಳ ಚಾರ್ಜಿಂಗ್ ಸ್ಟೇಷನ್‌ಗಳ ಕುರಿತು ಸ್ಥಳ ಮಾಹಿತಿಯು ಗೂಗಲ್ ಮ್ಯಾಪ್ಸ್‌ನಲ್ಲಿಯೂ ಲಭ್ಯವಿದೆ.",
  },
  {
    id: 3,
    question: "ವಾಣಿಜ್ಯ ಎಲೆಕ್ಟ್ರಿಕ್ ಆಟೋಗೆ ಪ್ರಮಾಣಿತ ವೈಶಿಷ್ಟ್ಯಗಳು ಯಾವುವು?",
    answer:
      "ವಾಣಿಜ್ಯ ವಿದ್ಯುತ್ ಆಟೋ ಸಾಮಾನ್ಯವಾಗಿ ಬಾಡಿ, ಬ್ಯಾಟರಿ ಮತ್ತು ಮೋಟಾರ್ ಅನ್ನು ಹೊಂದಿರುತ್ತದೆ. ವಾಣಿಜ್ಯ ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನಗಳು ಗರಿಷ್ಠ 50 kmph ವೇಗವನ್ನು ಹೊಂದಿವೆ ಮತ್ತು ಲೋಡ್ ಹೆಚ್ಚಾದಾಗ ತಾತ್ಕಾಲಿಕವಾಗಿ ವೇಗವನ್ನು ಹೆಚ್ಚಿಸಲು ಬೂಸ್ಟ್ ಮೋಡ್ ಅನ್ನು ಹೊಂದಿರುತ್ತದೆ.",
  },
  {
    id: 4,
    question: "ಎಲೆಕ್ಟ್ರಿಕ್ ಆಟೋಗಳು ಸುರಕ್ಷಿತವೇ?",
    answer:
      "ಎಲ್ಲಾ EV ಬ್ಯಾಟರಿಗಳು ರಾಷ್ಟ್ರೀಯ ಅನುಮೋದನಾ ಬೋರ್ಡ್ ಫಾರ್ ಟೆಸ್ಟಿಂಗ್ ಅಂಡ್ ಕ್ಯಾಲಿಬ್ರೇಶನ್ ಲ್ಯಾಬ್‌ಗಳಲ್ಲಿ ಕಠಿಣ ಪರೀಕ್ಷೆ ಮಾಡಬೇಕಾಗುತ್ತದೆ. ಭಾರತದಲ್ಲಿ ಪ್ರಮಾಣೀಕರಣ ಸಂಸ್ಥೆಗಳು ಮತ್ತು ಚಾರ್ಜರ್‌ಗಳನ್ನು ಪ್ರಮಾಣೀಕರಿಸುತ್ತವೆ. ಹೆಚ್ಚು ಸುರಕ್ಷಿತವಾಗಿ EV ಚಾಲನೆ ಮಾಡಬಹುದು.",
  },
  {
    id: 5,
    question: "ಮಳೆಯ ಸಮಯದಲ್ಲಿ ನಾನು ನನ್ನ ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನವನ್ನು ಓಡಿಸಬಹುದೇ?",
    answer:
      "ಹೌದು, ಮಳೆಯ ಸಮಯದಲ್ಲಿ ನಿಮ್ಮ ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನವನ್ನು ಯಾವುದೇ ಚಿಂತೆಯಿಲ್ಲದೆ ಓಡಿಸಬಹುದು.",
  },
  {
    id: 6,
    question:
      "Turno.club ಎಂದರೇನು ಮತ್ತು ನಾನು Turno ಮೂಲಕ ನನ್ನ EV ಅನ್ನು ಏಕೆ ಖರೀದಿಸಬೇಕು?",
    answer:
      "Turno ಮಾರುಕಟ್ಟೆಯಲ್ಲಿ ಬಾಜಾರದಲ್ಲಿ ಲಭ್ಯವಿರುವ ಎಲ್ಲಾ ಉತ್ತಮ ವ್ಯಾಪಾರಿಕ ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನಗಳನ್ನು ಖರೀದಿಸಲು ಒಂದು-ನಿಲುಗಡೆ ತಾಣವಾಗಿದೆ. ನಾವು ಖರೀದಿದಾದ ಎಲೆಕ್ಟ್ರಿಕ್ 3-ಚಕ್ರ ವಾಹನಕ್ಕಾಗಿ ವಿಪಣಿಪರರಿಗೆ ಸಹಾಯ ಮಾಡುತ್ತೇವೆ ಮತ್ತು ಸುಲಭ ಹಣಕಾಸು ಮತ್ತು ಖಚಿತ ಮರುಮಾರಾಟದಿಂದ ಜೊತೆಗೂಡಿದೆ.",
  },
];

export const loanEmiFAQ = [
  {
    id: 1,
    question:
      "What is the process of applying for a commercial EV loan at Turno?",
    answer:
      "You can apply online for your EV Three Wheeler Loan via the Turno Website. you can talk to our EV experts by calling us at 080 4748 2233 and understand the process of applying for an EV loan.",
  },
  {
    id: 2,
    question: "Can I get 100% funding for buying an EV three-wheeler?",
    answer:
      "Turno offers up to 94% on-road funding. It is applicable for both existing customers as well as new customers based on their profile.",
  },
  {
    id: 3,
    question:
      "What’s the minimum credit score I need to get an EV loan for an EV Three Wheeler?",
    answer:
      "At Turno, we don’t have to specify a minimum Credit Score for getting an EV Three Wheeler loan. However, a lower Credit Score may lead to the approval of lower loan amounts. Make sure your Credit Score is more than 600, which is generally considered good.",
  },
  {
    id: 4,
    question:
      "What are the minimum and maximum tenures of an EV Three Wheeler Loan?",
    answer:
      "Turno offers flexible tenures on their EV three-wheeler Loans product. The minimum and maximum tenure are from 12 months to 60 months.",
  },
  {
    id: 5,
    question: "Do I have the option to pre-pay the entire loan amount?",
    answer:
      "Yes, you can pre-pay the loan at any time after a lock-in period of three months at a nominal charge.",
  },
  {
    id: 6,
    question: "Do I need a guarantor?",
    answer:
      "No, generally a loan guarantor is not required to avail of an EV loan. However, in case you don't meet the Bank's norms then you may be required to have a guarantor surety. A guarantor becomes a co-applicant and becomes liable to repay the loan in the event of default.",
  },
  {
    id: 7,
    question: "How long will it take to process the loan?",
    answer:
      "Loan processing and disbursement will take less than 2 working days post-submission of all requisite documents and information.",
  },
  {
    id: 8,
    question: "What are the different modes of loan repayment?",
    answer:
      "Repayments are enabled through NACH. If the repayment through these instruments is not cleared, there are alternate digital repayment modes.",
  },
  {
    id: 9,
    question: "How can I contact Turno about the disbursement of my EV loan?",
    answer:
      "Customers can raise their requests digitally or reach out to sales@turno.club",
  },
  {
    id: 10,
    question: "Is there a processing fee charged by Turno? If yes, how much?",
    answer:
      "There is a Service charge of 1-2% of the principal amount which covers everything including Stamp duties, Legal fees, etc.",
  },
  {
    id: 11,
    question: "Are there any additional benefits of taking Finance from Turno?",
    answer:
      "Turno offers an EV resale option after three years for a maximum of up to INR 1,50,000. We also provide a Telematics App where owners can track their vehicle, battery life and usage, and charging details among others.",
  },
];

export const tcoData = [
  {
    id: 1,
    question:
      "What is the process of applying for a commercial EV loan at Turno?",
    answer:
      "You can apply online for your EV Three Wheeler Loan via the Turno Website. You can talk to our EV experts by calling us at 080 4748 2233 and understand the process of applying for an EV loan.",
  },
  {
    id: 2,
    question: "How much can I save by switching to an electric vehicle?",
    answer:
      "When you buy an electric vehicle, you can save up to 90% of your fuel expense. Additionally, you can save a lot on the maintenance charges as well. You will get govt. subsidies and tax benefits when you purchase an EV.",
  },
  {
    id: 3,
    question:
      "What is more profitable? Buying an electric vehicle or renting a diesel vehicle?",
    answer:
      "Buying an EV would be a better financial decision as you would save the diesel and maintenance expenses. Also, as the EMI of an EV starts at ₹12,999, there wouldn't be much difference between EMI amount and the rent you pay. So, after a certain time, you will be EMI-free and have ownership of the vehicle.",
  },
  {
    id: 4,
    question:
      "What is the Total Cost of Ownership (TCO) and why is it important?",
    answer:
      "The Total Cost of Owning a vehicle (TCO) is the sum of all expenses associated with owning any vehicle. It includes purchase price, fuel, insurance, maintenance, and more. It's important because it helps you understand the real cost of owning a vehicle over time so that you can make informed decisions. To know more, watch this video - https://www.youtube.com/watch?v=t2Tn2xc0npc",
  },
  {
    id: 5,
    question: "Can I get 100% funding for buying an EV three-wheeler?",
    answer:
      "Turno offers up to 94% on-road funding. It is applicable for both existing customers as well as new customers based on their profile.",
  },
  {
    id: 6,
    question:
      "What’s the minimum credit score I need to get an EV loan for an EV Three Wheeler?",
    answer:
      "At Turno, we don’t have to specify a minimum Credit Score for getting an EV Three Wheeler loan. However, a lower Credit Score may lead to the approval of lower loan amounts. Make sure your Credit Score is more than 600, which is generally considered good.",
  },
  {
    id: 7,
    question: "How long will it take to disburse my EV loan?",
    answer: "Generally, it takes only 48 hours to disburse EV loans at Turno.",
  },
  {
    id: 8,
    question:
      "How do you determine the loan eligibility of any potential customers?",
    answer:
      "The loan eligibility depends on a number of factors such as credit score, income, downpayment, etc. To know more, please talk to Turno Experts at - 080 4748 2233",
  },
];

export const faqKannadaTCOData = [
  {
    id: 1,
    question: "ಟರ್ನೊನಲ್ಲಿ ವಾಣಿಜ್ಯಿಕ EV ಸಾಲ ಅರ್ಜಿಸುವ ಕ್ರಿಯೆಯೇನು?",
    answer:
      "ನೀವು ಟರ್ನೊ ವೆಬ್ಸೈಟ್ ಮೂಲಕ ನಿಮ್ಮ EV ಥ್ರಿ ವೀಲರ್ ಸಾಲಕ್ಕಾಗಿ ಆನ್‌ಲೈನ್ ಅರ್ಜಿಸಬಹುದು. ನೀವು 080 4748 2233 ನಂಬರ್‌ಗೆ ಕರೆ ಮಾಡಿ, ನಿಮ್ಮ EV ಸಾಲಕ್ಕಾಗಿ ಅರ್ಜಿಸುವ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಅರ್ಥಮಾಡಬಹುದು.",
  },
  {
    id: 2,
    question:
      "ನೀವು ಯಾವ ಮೂಲಕವನ್ನು ಯಾವ ಸಾಂಭಾವಿಕ ಗ್ರಾಹಕರ ಸಾಲ ಅರ್ಹತೆಯನ್ನು ನಿರ್ಧರಿಸುತ್ತೀರಿ?",
    answer:
      "ಸಾಲ ಅರ್ಹತೆ ಕ್ರೆಡಿಟ್ ಸ್ಕೋರ್, ಆದಾಯ, ಡೌನ್‌ಪೇಮೆಂಟ್ ಇತ್ಯಾದಿ ಹಲವು ಅಂಶಗಳ ಮೇಲೆ ನಿರ್ಧಾರಿತವಾಗುತ್ತದೆ. ಇನ್ನಷ್ಟು ತಿಳಿಯಲು, ದಯವಿಟ್ಟು 080 4748 2233 ನಂಬರಿಗೆ ಟರ್ನೊ ಎಕ್ಸ್ಪರ್ಟ್‌ಗಳನ್ನು ನಮಗೆ ಮಾತನಾಡಿ.",
  },
  {
    id: 3,
    question: "ನಾನು ಏನಿನಿಂದ ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನಗಳಿಗೆ ಬದಲಾಗಿ ಯಾವಾಗ ಉಳಿಸಬಹುದು?",
    answer:
      "ನೀವು ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನವನ್ನು ಖರೀದಿಸಿದಾಗ, ನಿಮ್ಮ ಇಂಧನ ವೆಚ್ಚದ 90% ವರೆಗೆ ಉಳಿಸಬಹುದು. ಹೆಚ್ಚುವರಿಯಾಗಿ, ನೀವು ನಿರ್ವಹಣಾ ಶುಲ್ಕದ ಮೇಲೆ ಸಾಕಷ್ಟು ಉಳಿಸಬಹುದು. ನೀವು EV ಅನ್ನು ಖರೀದಿಸಿದಾಗ ನೀವು ಸರ್ಕಾರದ ಸಬ್ಸಿಡಿಗಳು ಮತ್ತು ತೆರಿಗೆ ಪ್ರಯೋಜನಗಳನ್ನು ಪಡೆಯುತ್ತೀರಿ.",
  },
  {
    id: 4,
    question:
      "ಯಾವುದು ಹೆಚ್ಚು ಲಾಭದಾಯಕ? ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನವನ್ನು ಖರೀದಿಸುವುದೇ ಅಥವಾ ಡೀಸೆಲ್ ವಾಹನವನ್ನು ಬಾಡಿಗೆಗೆ ಪಡೆಯುವುದೇ?",
    answer:
      "ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನವನ್ನು ಖರೀದಿಸುವುದು ಉತ್ತಮ ಆರ್ಥಿಕ ನಿರ್ಧಾರವಾಗಿದೆ, ಏನಕ್ಕೆ ನೀವು ಡೀಸೆಲ್ ಮತ್ತು ನಿರ್ವಹಣಾ ವೆಚ್ಚವನ್ನು ಉಳಿಸುವಿರಿ. ಇದಲ್ಲದೆ, EV ಯ EMI ₹12,999 ರಿಂದ ಪ್ರಾರಂಭವಾಗುವುದರಿಂದ, EMI ಮೊತ್ತ ಮತ್ತು ನೀವು ಪಾವತಿಸುವ ಬಾಡಿಗೆಯ ನಡುವೆ ಹೆಚ್ಚಿನ ವ್ಯತ್ಯಾಸವಿರುವುದಿಲ್ಲ. ಆದ್ದರಿಂದ, ನಿರ್ದಿಷ್ಟ ಸಮಯದ ನಂತರ, ನೀವು EMI-ಮುಕ್ತರಾಗುತ್ತೀರಿ ಮತ್ತು ವಾಹನದ ಮಾಲೀಕತ್ವವನ್ನು ಹೊಂದಿರುತ್ತೀರಿ.",
  },
  {
    id: 5,
    question: "ನಾನು ಎವಿ ಥ್ರೀ ವೀಲರ್ ಖರೀದಿಸಲು 100% ವಾಣಿಜ್ಯವನ್ನು ಹೇಗೆ ಪಡೆಯಬಹುದು?",
    answer:
      "ಟರ್ನೊ 94% ವರೆಗೆ ಆನ್-ರೋಡ್ ಫಂಡಿಂಗ್ ನೀಡುತ್ತದೆ. ಇದು ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಗ್ರಾಹಕರು ಹಾಗೂ ಅವರ ಪ್ರೊಫೈಲ್ ಅನ್ನು ಆಧರಿಸಿ ಹೊಸ ಗ್ರಾಹಕರಿಗೂ ಅನ್ವಯಿಸುತ್ತದೆ.",
  },
  {
    id: 6,
    question:
      "ನನಗೆ EV ಥ್ರೀ ವೀಲರ್ ಸಾಲಕ್ಕಾಗಿ ಎತ್ತಬೇಕಾದ ಕನಿಷ್ಠ ಕ್ರೆಡಿಟ್ ಸ್ಕೋರ್ ಯಾವುದು?",
    answer:
      "ಟರ್ನೊದಲ್ಲಿ, ಎವಿ ಥ್ರೀ ವೀಲರ್ ಸಾಲವನ್ನು ಪಡೆಯಲು ನಾವು ಕನಿಷ್ಠ ಕ್ರೆಡಿಟ್ ಸ್ಕೋರ್ ವಿಶಿಷ್ಟಗೊಳಿಸಲೇಬೇಕಾಗುವುದಿಲ್ಲ. ಹೆಚ್ಚೇನಲ್ಲದೆ, ಕನಿಷ್ಠ ಕ್ರೆಡಿಟ್ ಸ್ಕೋರ್ ಕಡಿಮೆ ಸಾಲ ಮೊತ್ತದ ಅನುಮೋದನೆಗೆ ಕಾರಣವಾಗಬಹುದು. ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ ನಿಮ್ಮ ಕ್ರೆಡಿಟ್ ಸ್ಕೋರ್ ಸಾಮಾನ್ಯವಾಗಿ ಚೆನ್ನಾಗಿದೆಯೆಂದು.",
  },
  {
    id: 7,
    question: "ನನ್ನ EV ಸಾಲವನ್ನು ವಿತರಿಸುವುದಕ್ಕೆ ಎಷ್ಟು ದಿನಗಳು ಆವಶ್ಯಕವಿದೆ?",
    answer:
      "ನೀವು ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಸಲ್ಲಿಸಿದ ನಂತರ, EV ಸಾಲವನ್ನು ಸ್ವಿಫ್ಟ್ ಮಾಡಬೇಕಾದದ್ದು ಸಾಮಾನ್ಯವಾಗಿ 5 ದಿನಗಳಲ್ಲಿ ಆಗುತ್ತದೆ.",
  },
  {
    id: 8,
    question: "ನಾನು ನಿಮ್ಮ ವಾಣಿಜ್ಯಿಕ EV ಸಾಲಕ್ಕೆ ಹೇಗೆ ಅರ್ಜಿಸಬಹುದು?",
    answer:
      "ನೀವು ಟರ್ನೊ ವೆಬ್ಸೈಟ್ ಮೂಲಕ ನಿಮ್ಮ EV ಥ್ರೀ ವೀಲರ್ ಸಾಲಕ್ಕಾಗಿ ಆನ್‌ಲೈನ್ ಅರ್ಜಿಸಬಹುದು. ನೀವು 080 4748 2233 ನಂಬರ್‌ಗೆ ಕರೆ ಮಾಡಿ, ನಿಮ್ಮ EV ಸಾಲಕ್ಕಾಗಿ ಅರ್ಜಿಸುವ ಪ್ರಕ್ರಿಯೆಯನ್ನು ಅರ್ಥಮಾಡಬಹುದು.",
  },
];

export const faq3wEvEmi11KData = [
  {
    id: 1,
    question:
      "Turno.club ಎಂದರೇನು ಮತ್ತು ನಾನು ಟರ್ನೊದಿಂದ ನನ್ನ ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನವನ್ನು ಏಕೆ ಖರೀದಿಸಬೇಕು?",
    answer:
      "ಟರ್ನೊ ಒಂದು ಮಾರುಕಟ್ಟೆಯಾಗಿದ್ದು, ಮಾರುಕಟ್ಟೆಯಲ್ಲಿ ಲಭ್ಯವಿರುವ ಎಲ್ಲಾ ಅತ್ಯುತ್ತಮ ಲಗೇಜ್ ಮತ್ತು ಪ್ಯಾಸೆಂಜರ್ ಎಲೆಕ್ಟ್ರಿಕ್ ತ್ರಿಚಕ್ರ ವಾಹನಗಳನ್ನು ನೀಡುತ್ತದೆ. ಖರೀದಿದಾರರು ತಮ್ಮ ವ್ಯವಹಾರಕ್ಕಾಗಿ ಅತ್ಯುತ್ತಮ ಎಲೆಕ್ಟ್ರಿಕ್ ತ್ರಿಚಕ್ರ ವಾಹನವನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ನಾವು ಸಹಾಯ ಮಾಡುತ್ತೇವೆ ಮತ್ತು ಸರಳ EV ಲೋನ್ ಗಳು ಮತ್ತು ಖಚಿತ ಮರುಮಾರಾಟ ಆಯ್ಕೆಗಳನ್ನು ಸಹ ಒದಗಿಸುತ್ತೇವೆ.",
  },
  {
    id: 2,
    question: " ಟರ್ನೊದಲ್ಲಿ ಎಕ್ಸ್ ಚೇಂಜ್ ಆಫರ್ ಇದೆಯಾ?",
    answer:
      "ಹೌದು, ಟರ್ನೊ ಎಕ್ಸ್ ಚೇಂಜ್ ಆಫರ್ ನೀಡುತ್ತದೆ, ಅಲ್ಲಿ ನೀವು ನಿಮ್ಮ ಹಳೆಯ ವಾಹನವನ್ನು ಮಾರಾಟ ಮಾಡಬಹುದು ಮತ್ತು ಹೊಸ ವಾಣಿಜ್ಯ ಎಲೆಕ್ಟ್ರಿಕ್ ತ್ರಿಚಕ್ರ ವಾಹನವನ್ನು ಪಡೆಯಬಹುದು. ಈ ವಿನಿಮಯ ಕೊಡುಗೆಯ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿಯಲು, 08047842233 ನಲ್ಲಿ ನಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸಿ.",
  },
  {
    id: 3,
    question: "ಟರ್ನೊ ತ್ರಿಚಕ್ರ ಎಲೆಕ್ಟ್ರಿಕ್ ಆಟೋಗಳಿಗೆ ಸಾಲವನ್ನು ಒದಗಿಸುತ್ತದೆಯೇ?",
    answer:
      "ಟರ್ನೊ ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನಗಳಿಗೆ 49,999 ರೂ.ಗಳ ಡೌನ್ ಪೇಮೆಂಟ್ ಮತ್ತು ಕಡಿಮೆ ಬಡ್ಡಿದರದಲ್ಲಿ ಅತ್ಯುತ್ತಮ ಲೋನ್ ಸಹಾಯ ಒದಗಿಸುತ್ತದೆ. ನಾವು ಕೈಗೆಟುಕುವ ಶುಲ್ಕಗಳೊಂದಿಗೆ ಸಾಲಗಳನ್ನು ಒದಗಿಸುತ್ತೇವೆ ಮತ್ತು ಯಾವುದೇ ಇತರೆ ಶುಲ್ಕಗಳಿಲ್ಲ.",
  },
  {
    id: 4,
    question: "ಟರ್ನೊದಿಂದ ಪಡೆಯುವ ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನಗಳ ವಾರಂಟಿ ಏನು?",
    answer:
      "ನಾವು ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನದ ಬ್ಯಾಟರಿಯ ಮೇಲೆ 3 ವರ್ಷಗಳ ವಾರಂಟಿ ನೀಡುತ್ತೇವೆ. ಹೆಚ್ಚುವರಿಯಾಗಿ, ನೀವು ನಿಮ್ಮ ವಾಹನವನ್ನು ಟರ್ನೊಗೆ ಮರುಮಾರಾಟ ಮಾಡಲು ಬಯಸಿದರೆ, 3 ವರ್ಷಗಳ ನಂತರ ನಾವು ಖಚಿತ ಮರುಮಾರಾಟ ₹ 1.5 ಲಕ್ಷ ಅಥವಾ ವಾಹನದ ಬೆಲೆಯ 40% ವರೆಗೂ ನೀಡುತ್ತೇವೆ.",
  },
  {
    id: 5,
    question:
      "ಎಲೆಕ್ಟ್ರಿಕ್ ಆಟೋ ರಿಕ್ಷಾಗಳ ಮೇಲೆ ಸರ್ಕಾರದ ಸಬ್ಸಿಡಿಗಳನ್ನು ಪಡೆಯಲು ಟರ್ನೊ ನನಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆಯೇ?",
    answer:
      "ಖಂಡಿತ! ಟರ್ನೊ ನೀಡುವ ಕೊಟೇಷನ್ ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನಗಳಿಗೆ ಸಬ್ಸಿಡಿಗಳು ಮತ್ತು ನೋಂದಣಿ ಶುಲ್ಕಗಳನ್ನು ಒಳಗೊಂಡಿದೆ. ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನ ಸಬ್ಸಿಡಿ ಪಡೆಯಲು ಅಗತ್ಯವಾದ ದಾಖಲೆಗಳೊಂದಿಗೆ ನಾವು ಸಹಾಯ ಮಾಡುತ್ತೇವೆ.",
  },
  {
    id: 6,
    question:
      "ನಾನು ಬುಕಿಂಗ್ ಮೊತ್ತವನ್ನು ಠೇವಣಿ ಮಾಡಿದ ನಂತರ ನನ್ನ ವಾಹನವನ್ನು ತಲುಪಿಸಲು ಎಷ್ಟು ಸಮಯ ತೆಗೆದುಕೊಳ್ಳುತ್ತದೆ?",
    answer:
      "ಸಾಮಾನ್ಯವಾಗಿ, ಬುಕಿಂಗ್ ಮೊತ್ತವನ್ನು ಠೇವಣಿ ಮಾಡಿದ ನಂತರ ವಾಹನವನ್ನು ತಲುಪಿಸಲು ನಮಗೆ 1 ದಿನ ತೆಗೆದುಕೊಳ್ಳುತ್ತದೆ.",
  },
  {
    id: 7,
    question:
      " ನನ್ನ ಕ್ರೆಡಿಟ್ ಸ್ಕೋರ್ ನನಗೆ ತಿಳಿದಿಲ್ಲ. ನನ್ನ E-ರಿಕ್ಷಾಕ್ಕಾಗಿ ನಾನು ಇನ್ನೂ ಸಾಲ ಪಡೆಯಬಹುದೇ?",
    answer:
      "ಟರ್ನೊದಲ್ಲಿ, ನಾವು ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನಗಳಿಗೆ ಖಾತರಿಪಡಿಸಿದ ಆನ್-ರೋಡ್ ಹಣಕಾಸು ಒದಗಿಸುತ್ತೇವೆ. ನಾವು ತ್ವರಿತ ಕ್ರೆಡಿಟ್ ಅರ್ಹತಾ ಚೆಕ್ ಗಳನ್ನು ಸಹ ನೀಡುತ್ತೇವೆ. EV ಸಾಲಗಳ ಬಗ್ಗೆ ಇನ್ನಷ್ಟು ತಿಳಿದುಕೊಳ್ಳಲು, ಇಂದು 080 4784 2233 ನಲ್ಲಿ ಟರ್ನೊ EV ಎಕ್ಸ್ ಪರ್ಟನ್ನು ಸಂಪರ್ಕಿಸಿ.",
  },
  {
    id: 8,
    question:
      "ನನ್ನ ಬ್ಯಾಟರಿ ಎಲೆಕ್ಟ್ರಿಕ್ ವಾಹನ ಸಾಲದ ಅತ್ಯಂತ ಕಡಿಮೆ ಮತ್ತು ದೀರ್ಘಾವಧಿಯ ಅವಧಿ ಯಾವುದು?",
    answer:
      "ಟರ್ನೊದೊಂದಿಗೆ, ನೀವು 12 ರಿಂದ 48 ತಿಂಗಳವರೆಗಿನ ಅವಧಿಗೆ EV ಸಾಲವನ್ನು ಪಡೆಯಬಹುದು.",
  },
];

export const iceEVFAQs = [
  {
    id: 1,
    question: "Are electric autos more expensive than fuel-powered autos?",
    answer:
      "No, electric autos and fuel-powered autos need a similar investment, which is nearly Rs. 2 lakhs. However, the total cost of owning an EV is less than half of owning a fuel-powered vehicle while calculated for three years.",
  },
  {
    id: 2,
    question: "How much can a user save with an electric commercial vehicle?",
    answer:
      "With electric tempo vehicles, you can save on your daily expenses in running costs and maintenance costs other than the subsidy received from the government. Running cost of EV - ₹1/km, Maintenance cost of EV - ₹3000/year.",
  },
  {
    id: 3,
    question: "What are the standard features of a commercial electric auto?",
    answer:
      "A commercial electric auto generally has a body, battery, and motor. Commercial electric vehicles have a standard maximum speed of 50 kmph and have a boost mode to temporarily increase the speed when the load is higher.",
  },
  {
    id: 4,
    question: "What is the range of an electric load vehicle?",
    answer:
      "An electric luggage auto can go up to 100 km in one full charge. The range may vary depending on the vehicle variant as well as the load carried on the electric tempo vehicle. If the load is higher than the recommended limit, the battery may discharge faster than usual.",
  },
  {
    id: 5,
    question: "Do electric autos come with warranties?",
    answer:
      "Yes, electric load vehicles come with a battery warranty from vehicle manufacturers. Most of the commercial electric vehicles come with a warranty of 3 years or up to 80,000 km.",
  },
  {
    id: 6,
    question:
      "What kind of maintenance does an electric three-wheeler tempo need?",
    answer:
      "Electric autos require less maintenance compared to diesel/CNG vehicles. What you need to take care of are - the brakes, steering and suspension, the electric motor and the battery.",
  },
  {
    id: 7,
    question: "Can I drive my electric tempo vehicle during rain?",
    answer:
      "Yes, you can drive your electric vehicle during rain without any worries.",
  },
  {
    id: 8,
    question: "Are EVs safe?",
    answer:
      "All EV batteries go through rigorous testing procedures at National Accreditation Board for Testing and Calibration Laboratories (NABL) certified labs. Certification agencies in India like ARAI, and ICAT certify EVs and chargers. Thus, it is safe to drive an EV.",
  },
  {
    id: 9,
    question: "Is there an option to take a test drive of electric vehicles?",
    answer:
      "Yes, you can opt for a test drive for an electric commercial vehicle at the Turno showroom as well as at your home. We offer doorstep test drives so that you can experience the vehicle at your convenient place and time. Visit https://www.turno.club/vehicles to book a slot.",
  },
  {
    id: 10,
    question:
      "What is the process for purchasing an Etrio Tour Max ++ from Turno?",
    answer:
      "The process is simple. You can visit our website or app to explore the vehicle and its variants. Once you've made your selection, you can proceed with the booking and financing options available. Our experts will guide you through the entire process.",
  },
];

export const goodsEvFAQs = [
  {
    id: 1,
    question:
      "Are goods electric vehicle more expensive than fuel-powered autos?",
    answer:
      "No, electric autos and fuel-powered autos need a similar investment, which is nearly Rs. 2 lakhs. However, the total cost of owning an EV is less than half of owning a fuel-powered vehicle while calculated for three years. To know more about the total cost of owing, kindly contact Turno EV experts.",
  },
  {
    id: 2,
    question: "What kind of maintenance do electric vehicles need?",
    answer:
      "Electric loaders require less maintenance compared to fuel-powered vehicles. What you need to take care of are- the brakes, steering and suspension, the electric motor and the battery.",
  },
  {
    id: 3,
    question:
      "Is there an option to book a test drive before buying an electric vehicle?",
    answer:
      "Yes, Turno provides test drives for three-wheeler goods electric vehicles. You can contact Turno CEV experts to book a doorstep test drive at your convenient location and time or else you can visit the nearest showroom.",
  },
  {
    id: 4,
    question: "Do electric autos come with warranties?",
    answer:
      "Yes, electric luggage vehicles come with a battery warranty from vehicle manufacturers. Most commercial electric vehicles come with a warranty of 3 years or up to 80,000 km.",
  },
  {
    id: 5,
    question:
      "How can I enhance the driving range of my electric carrier auto?",
    answer:
      "Driving smoothly is the key to increasing the range of your electric loader. Avoiding sudden acceleration and maintaining the tires in good condition can improve the driving range of your electric car.",
  },
  {
    id: 6,
    question: "Can I load up the electric tempo beyond the load limit?",
    answer:
      "Yes, you can increase the load beyond the limit but it is not recommended. In case you load more than the limit in the electric auto, the battery will discharge faster. So, we recommend following the instructions of the manufacturer.",
  },
  {
    id: 7,
    question:
      "Where do I service my three-wheeler electric truck and what will it cost me?",
    answer:
      "Servicing can be done at the nearest electric vehicle showroom. On average, the maintenance cost of EVs is ₹1000 per year.",
  },
  {
    id: 8,
    question: "How much can I save by switching to an electric loader?",
    answer:
      "When you buy an electric vehicle, you can save up to 90% of your fuel expense. Additionally, you can save a lot on the maintenance charges as well. You will get govt. subsidies and tax benefits when you purchase an EV.",
  },
  {
    id: 9,
    question:
      "What is more profitable? Buying a loader e-rickshaw or renting a diesel vehicle?",
    answer:
      "Buying an EV would be a better financial decision as you would save the diesel and maintenance expenses. Also, as the EMI of an EV starts at ₹12,999, there wouldn't be much difference between EMI amount and the rent you pay. So, after a certain time, you will be EMI-free and have ownership of the vehicle.",
  },
];

export const twoto3wHindiFAQs = [
  {
    id: 1,
    question:
      "Turno.club क्या है और मुझे अपनी इलेक्ट्रिक गाड़ी को Turno के माध्यम से क्यों खरीदना चाहिए?",
    answer:
      "Turno एक ऐसा एक स्थान है जहां बाजार में उपलब्ध सभी सर्वोत्तम व्यावसायिक इलेक्ट्रिक गाड़ियों उपलब्ध है। हम खरीदारों को उनके व्यापार के लिए सर्वोत्तम इलेक्ट्रिक 3-व्हीलर गाड़ी का चयन करने में मदद करते हैं, साथ ही सरल EV loan और गारंटीड रीसेल भी प्रदान करते हैं।",
  },
  //do it for all
  {
    id: 2,
    question: "क्या Turno के पास एक्सचेंज ऑफ़र है?",
    answer:
      "हाँ, Turno के पास एक्सचेंज ऑफ़र है जहां आप अपनी पुरानी गाड़ी को बेचकर नयी व्यावसायिक इलेक्ट्रिक 3-व्हीलर प्राप्त कर सकते हैं। इस एक्सचेंज ऑफ़र के बारे में अधिक जानने के लिए, हमें 08047842233 पर संपर्क करें।",
  },
  {
    id: 3,
    question: "क्या Turno  तीन पहिये इलेक्ट्रिक ऑटो के लिए लोन प्रदान करता है?",
    answer:
      "Turno देता है सबसे कम ₹49,999 की डाउन पेमेंट के साथ कम  ब्याज दर पे इलेक्ट्रिक गाड़ियों पर सर्वोत्तम फाइनेंसिंग प्रदान करता है। हम सस्ती प्रोसेसिंग शुल्क और कोई हिडन चार्जेज रहित लोन प्रदान करते हैं।",
  },
  {
    id: 4,
    question: "इलेक्ट्रिक गाड़ी पर Turno की वारंटी क्या है?",
    answer:
      "हम इलेक्ट्रिक गाड़ी बैटरी पर 3 साल की वारंटी प्रदान करते हैं। विशेषकर, यदि आप अपनी गाड़ी को Turno को फिरसे बेचना चाहते हैं, तो हम 3 साल के बाद एक गारंटीत पुनर्विक्रय मूल्य प्रदान करते हैं, जो ₹1.5 लाख या गाड़ी के कीमत का 40% होता है।",
  },
  {
    id: 5,
    question: "क्या Turno मुझे इलेक्ट्रिक कार्गो गाड़ी पर सरकारी सब्सिडी प्राप्त करने में मदद करेगा?",
    answer:
      "बिल्कुल! Turno की कोटेशन में इलेक्ट्रिक गाड़ियों के लिए सब्सिडी और पंजीकरण शुल्क शामिल हैं। हम इलेक्ट्रिक गाड़ी सब्सिडी प्राप्त करने के लिए दस्तावेजीकरण में सहायता भी प्रदान करते हैं।",
  },
  {
    id: 6,
    question: "मेरे व्यवसाय के उपयुक्त बैटरी इलेक्ट्रिक गाड़ी को कैसे खोजा जाएगा?",
    answer:
      "अपने व्यवसाय के लिए उपयुक्त EV  ढूंढने के लिए, आपको वांछित भार क्षमता, दूरी और बजट को ध्यान में रखना चाहिए। आप अपने व्यवसाय के लिए सर्वोत्तम गाड़ी खोजने में मदद के लिए Turno EV विशेषज्ञों से भी संपर्क कर सकते हैं।",
  },
  {
    id: 7,
    question: "मेरे बुकिंग राशि जमा करने के बाद मेरी गाड़ी को डिलीवरी करने में कितना समय लगेगा?",
    answer:
      "सामान्यतः, हमें बुकिंग राशि जमा करने के बाद गाड़ी डिलीवरी करने में 3  दिन लगते हैं।",
  },
  {
    id: 8,
    question: "मेरा क्रेडिट स्कोर मुझे नहीं पता है। क्या मैं फिर भी मेरे माल इलेक्ट्रिक गाड़ी के लिए लोन  प्राप्त कर सकता हूँ?",
    answer:
      "Turno पर, हम इलेक्ट्रिक गाड़ियों के लिए गारंटीड ऑन -रोड फाइनेंसिंग प्रदान करते हैं। हम तुरंत  क्रेडिट योग्यता जांच भी प्रदान करते हैं। EV लोन  के बारे में अधिक जानने के लिए, कृपया 080 4784 2233 पर Turno EV विशेषज्ञों से आज संपर्क करें।",
  },
  {
    id: 9,
    question: "मेरे बैटरी इलेक्ट्रिक गाड़ी लोन की सबसे लंबी और सबसे छोटी अवधि क्या है?",
    answer:
      "Turno के साथ, आप 12 से 48 महीनों की अवधि के लिए EV लोन  प्राप्त कर सकते हैं।",
  },
];

export const PassengerVehiclesHindiFAQs = [
  {
    id: 1,
    question:
      "Turno.club क्या है और मुझे अपनी इलेक्ट्रिक गाड़ी को Turno से क्यों खरीदना चाहिए?",
    answer:
      "Turno एक ऐसा एक मार्केटप्लेस है जहां बाजार में उपलब्ध सभी सर्वोत्तम कार्गो और सवारी इलेक्ट्रिक थ्री व्हीलर टेंपो उपलब्ध है। हम खरीदारों को उनके व्यापार के लिए सर्वोत्तम इलेक्ट्रिक 3-व्हीलर गाड़ी चुनने में मदद करते हैं, साथ ही सरल EV लोन और गारंटीड रीसेल भी प्रदान करते हैं।",
  },
  {
    id: 2,
    question: "क्या Turno के पास एक्सचेंज ऑफ़र है?",
    answer:
      "हाँ, Turno के पास एक्सचेंज ऑफ़र है जहां आप अपनी पुरानी गाड़ी को बेचकर नयी व्यावसायिक इलेक्ट्रिक 3-व्हीलर प्राप्त कर सकते हैं। इस एक्सचेंज ऑफ़र के बारे में अधिक जानने के लिए, हमें 08047842233 पर संपर्क करें।",
  },
  {
    id: 3,
    question: "क्या Turno  तीन पहिये इलेक्ट्रिक ऑटो के लिए लोन प्रदान करता है?",
    answer:
      "Turno देता है सबसे कम ₹49,999 की डाउन पेमेंट के साथ कम  ब्याज दर पे इलेक्ट्रिक गाड़ियों पर सर्वोत्तम फाइनेंसिंग प्रदान करता है। हम सस्ती प्रोसेसिंग शुल्क और कोई हिडन चार्जेज रहित लोन प्रदान करते हैं।",
  },
  {
    id: 4,
    question: "इलेक्ट्रिक गाड़ी पर Turno की वारंटी क्या है?",
    answer:
      "हम इलेक्ट्रिक गाड़ी बैटरी पर 3 साल की वारंटी प्रदान करते हैं। विशेषकर, यदि आप अपनी गाड़ी को Turno को फिरसे बेचना चाहते हैं, तो हम 3 साल के बाद एक गारंटीत पुनर्विक्रय मूल्य प्रदान करते हैं, जो ₹1.5 लाख या गाड़ी के कीमत का 40% होता है।",
  },
  {
    id: 5,
    question:
      " क्या Turno मुझे इलेक्ट्रिक ऑटो रिक्शा पर सरकारी सब्सिडी प्राप्त करने में मदद करेगा?",
    answer:
      "बिल्कुल! Turno की कोटेशन में इलेक्ट्रिक गाड़ियों के लिए सब्सिडी और रजिस्ट्रेशन चार्जे शामिल हैं। हम इलेक्ट्रिक गाड़ी सब्सिडी प्राप्त करने के लिए डॉक्यूमेंटेशन में  भी सहायता प्रदान करते हैं।",
  },
  {
    id: 6,
    question:
      "मेरे बुकिंग राशि जमा करने के बाद मेरी गाड़ी को डिलीवरी करने में कितना समय लगेगा?",
    answer:
      "सामान्यतः, हमें बुकिंग राशि जमा करने के बाद गाड़ी डिलीवरी करने में 1 दिन लगते हैं।",
  },
  {
    id: 7,
    question:
      "मेरा क्रेडिट स्कोर मुझे नहीं पता है। क्या मैं फिर भी मेरे ई रिक्शा लोडिंग के लिए लोन  प्राप्त कर सकता हूँ?",
    answer:
      "Turno पर, हम इलेक्ट्रिक गाड़ियों के लिए गारंटीड ऑन -रोड फाइनेंसिंग प्रदान करते हैं। हम तुरंत  क्रेडिट योग्यता जांच भी प्रदान करते हैं। EV लोन  के बारे में अधिक जानने के लिए, कृपया 080 4784 2233 पर Turno EV एक्सपर्ट से आज संपर्क करें।",
  },
  {
    id: 8,
    question:
      "मेरे बैटरी इलेक्ट्रिक गाड़ी लोन की सबसे लंबी और सबसे छोटी अवधि क्या है?",
    answer:
      "Turno के साथ, आप 12 से 48 महीनों की अवधि के लिए EV लोन  प्राप्त कर सकते हैं।",
  },
];
